import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const paymentComputed = {
  ...mapGetters('payment', ['getMerchantEntityDetails', 'getTransactionsData', 'getPaymentChannelCredentials']),
  ...mapGetters('transactions', ['getTransactionsDataForSelectedTransactionID']),
}

export const authMethods = mapActions('auth', ['logOut', 'register', 'resetPassword', 'sendResetPasswordLink', 'resetPassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const paymentMethods = mapActions('payment', [
  'updateMerchantEntityDetails', 'fetchMerchantEntityDetails', 'fetchTransactionsData', 'updatePaymentChannelCredentials', 'fetchPaymentChannelCredentials',
])

export const paymentLinks = mapActions('paymentlinks', [
  'fetchPaymentLinks',
  'addPaymentLinks'
])

export const virtualAccountList = mapActions('virtualaccount', [
  'fetchVirtualAccounts'
])

export const virtualAccountAdd = mapActions('virtualaccount', [
  'CreateVirtualAccount'
])

export const getPayoutList = mapActions('payout', [
  'fetchPayoutList'
])

export const getAccountStatement = mapActions('accountstatement',[
  'GetAccountStatement'
])

export const createPayouts = mapActions('payout',['CreatePayout']);

export const approvePayouts = mapActions('payout',['ApprovePayout']);

export const getBeneficiary = mapActions('beneficiary',['fetchBeneficiary']);

export const createBeneficiary = mapActions('beneficiary',['createBeneficiary']);


export const updateBeneficiaryAccount = mapActions('beneficiary',['updateBeneficiaryAccount']);

export const generateOtp = mapActions('payout',['generateOtpPayout']);

export const downloadExcel = mapActions('accountstatement',['DownloadExcelStatement']);


